import { Sling as Hamburger } from "hamburger-react";
import { useEffect, useState } from "react";
import { Collapse } from "react-collapse";
import { Link, NavLink } from "react-router-dom";
import logo from "../assets/logo.png";
import classNames from "../utils/combineClassnames";

const query = `
{
  musicDropdownItems(id: "6bjtx4HUFBWoNlz5TQmMfm") {
    linkItemsCollection {
      items {
        ... on SocialLinks {
          name
          url
        }
      }
    }
  }
}
`;

export default function Navigation(props) {
  const [dropdownItems, setDropdownItems] = useState(null);
  const [isMobileMusicOpen, setIsMobileMusicOpen] = useState(false);
  const { items } = props;
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/tsk3z5qo3555/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer e-_YNSArgFBpl7-Rlf46ojsxd6wkKyWlJkcnW9VdIVw",
        },
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }
        setDropdownItems(data.musicDropdownItems.linkItemsCollection.items);
      });
  }, []);

  return (
    <>
      <header className={classNames(!isOpen && "border-b-2 border-gray-50", "fixed w-full bg-white px-2 z-20")}>
        {/* MOBILE NAVIGATION */}
        <nav className="md:hidden flex items-center justify-center h-20">
          <Link to="/" className="flex flex-1 justify-center h-full">
            <img src={logo} className="w-24 ml-12" alt="" />
          </Link>
          <div className="ml-auto">
            <Hamburger toggled={isOpen} toggle={setOpen} />
          </div>
        </nav>

        {/* DESKTOP NAVIGATION */}
        <nav className="max-w-6xl mx-auto hidden md:flex items-center pt-1 pb-2 gap-8">
          <Link to="/">
            <img src={logo} className="w-24" alt="" />
          </Link>
          {items.map((item) => {
            const { name, linkTo, external } = item;
            if (external) {
              return (
                <div
                  key={name}
                  className="relative px-4 py-2 rounded-md text-sm font-medium border-transparent text-gray-800 hover:bg-gray-200 border-b-2 group"
                >
                  <span className="group-hover:text-black">{name}</span>
                  <div className="pt-4 top-8 hidden absolute left-0 group-hover:block hover:block">
                    <ul className="flex-col w-36 gap-4 flex bg-white p-4 rounded shadow">
                      {dropdownItems &&
                        dropdownItems.map((link) => {
                          const { name, url } = link;
                          return (
                            <li key={name} className="hover:underline hover:text-black">
                              <a href={url} target="_blank" rel="noreferrer">
                                {name}
                              </a>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              );
            }

            return (
              <NavLink
                to={linkTo}
                key={name}
                className={({ isActive }) =>
                  classNames(
                    isActive ? "border-black rounded-none" : "border-transparent text-gray-800 hover:bg-gray-200 hover:text-black",
                    "px-4 py-2 rounded-md text-sm font-medium border-b-2"
                  )
                }
              >
                {name}
              </NavLink>
            );
          })}
        </nav>
      </header>

      {/* MOBILE NAV POPOUT */}
      {isOpen && (
        <div className="bg-white fixed bottom-0 top-0 right-0 left-0 z-10 p-4 pt-20 pb-0 flex flex-col justify-center text-center gap-2">
          {items.map((item) => {
            const { name, linkTo, external } = item;

            if (external) {
              return (
                <>
                  <button
                    key={name}
                    onClick={() => {
                      setIsMobileMusicOpen(!isMobileMusicOpen);
                    }}
                    className="rounded-none text-4xl font-bold border-transparent px-4 py-2 text-gray-800"
                  >
                    {name}
                  </button>
                  {dropdownItems && (
                    <Collapse
                      isOpened={isMobileMusicOpen}
                      // initialStyle={{ marginTop: "-2rem" }}
                    >
                      {dropdownItems.map((link) => {
                        const { name, url } = link;
                        return (
                          <a className="block text-xl text-gray-800 mb-2" key={name} href={url} target="_blank" rel="noreferrer">
                            {name}
                          </a>
                        );
                      })}
                    </Collapse>
                  )}
                </>
              );
            }

            return (
              <NavLink
                key={name}
                to={linkTo}
                onClick={() => {
                  setOpen(!isOpen);
                }}
                className={({ isActive }) =>
                  classNames(isActive && "underline", "text-gray-800 px-4 py-2 rounded-md text-4xl font-bold border-transparent")
                }
              >
                {name}
              </NavLink>
            );
          })}
        </div>
      )}
    </>
  );
}
