import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { useEffect, useState } from "react";

const query = `
{
  echoesOfHomePageCollection {
    items {
      coverImage {
        url
        fileName
        title
      }
      title
      echoesOfHomeText {
        json
      }
      buttonText
      buttonLink
    }
  }
}
`;

const richTextOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <p className="mb-4 md:text-lg">{children}</p>;
    },
  },
};

export default function EchoesOfHome() {
  //   const [aboutPageContent, setAboutPageContent] = useState(null);
  const [echoesPageContent, setEchoesPageContent] = useState(null);

  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/tsk3z5qo3555/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer e-_YNSArgFBpl7-Rlf46ojsxd6wkKyWlJkcnW9VdIVw",
        },
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }
        console.log({ data });
        setEchoesPageContent(data.echoesOfHomePageCollection.items[0]);
      });
  }, []);

  useEffect(() => {
    document.querySelector("main").classList.toggle("about", true);

    return function cleanup() {
      document.querySelector("main").classList.toggle("about", false);
    };
  }, []);

  if (!echoesPageContent) return null;

  return (
    <div className="flex flex-col md:flex-row">
      <div
        className="w-full md:w-1/2 bg-center bg-cover md:h-auto aspect-square md:aspect-auto"
        style={{ backgroundImage: `url(${echoesPageContent.coverImage.url}?fit=fill&w=1200)` }}
      />
      <div className="md:w-1/2 p-8 md:py-32 lg:py-40 xl:py-48 2xl:py-52 max-w-lg lg:max-w-xl xl:max-w-2xl 2xl:max-w-3xl mx-auto">
        <h1 className="text-4xl font-bold leading-7 text-gray-900 sm:truncate sm:text-5xl sm:tracking-tight mt-2 mb-4">{echoesPageContent.title}</h1>
        {documentToReactComponents(echoesPageContent.echoesOfHomeText.json, richTextOptions)}
        <a
          className="mt-2 inline-block px-4 py-2 rounded-md font-medium border text-gray-800 hover:bg-gray-200 hover:text-black transition-all"
          href={echoesPageContent.buttonLink}
          target="_blank"
          rel="noreferrer"
        >
          {echoesPageContent.buttonText}
        </a>
      </div>
    </div>
  );
}
