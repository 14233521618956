import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import About from "./Pages/About";
import Contact from "./Pages/Contact";
import EchoesOfHome from "./Pages/EchoesOfHome";
import Home from "./Pages/Home";

import ApplemusicLogo from "./icons/Applemusic";
import InstagramLogo from "./icons/Instagram";
import SoundcloudLogo from "./icons/Soundcloud";
import SpotifyLogo from "./icons/Spotify";
import YoutubeLogo from "./icons/Youtube";

import "./App.css";
import Navigation from "./Components/Navigation";
import Photos from "./Pages/Photos";

const navigation = [
  { name: "Home", linkTo: "/", external: false },
  { name: "About", linkTo: "/about", external: false },
  { name: "Photos", linkTo: "/photos", external: false },
  { name: "Contact", linkTo: "/contact", external: false },
  { name: "Music", linkTo: "", external: true },
  { name: "Echoes Of Home", linkTo: "/echoesofhome", external: false },
];

const socialsQuery = `
{
  socialLinksCollection {
    items {
      name
      url
    }
  }
}
`;

function getSocialLogo(name) {
  switch (name) {
    case "Apple Music":
      return <ApplemusicLogo />;
    case "Instagram":
      return <InstagramLogo />;
    case "SoundCloud":
      return <SoundcloudLogo />;
    case "Spotify":
      return <SpotifyLogo />;
    case "YouTube":
      return <YoutubeLogo />;
    default:
      return <InstagramLogo />;
  }
}

function App() {
  const [socialLinks, setSocialLinks] = useState(null);

  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/tsk3z5qo3555/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer e-_YNSArgFBpl7-Rlf46ojsxd6wkKyWlJkcnW9VdIVw",
        },
        body: JSON.stringify({ query: socialsQuery }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }
        const links = data.socialLinksCollection.items.map((item) => {
          const { name, url } = item;
          return {
            name,
            url,
            logo: getSocialLogo(item.name),
          };
        });
        setSocialLinks(links);
      });
  }, []);

  return (
    <BrowserRouter>
      <Navigation items={navigation} />
      <main className="px-2 pt-24 md:pt-36">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/photos" element={<Photos />} />
          <Route path="/echoesofhome" element={<EchoesOfHome />} />
        </Routes>
      </main>
      <footer className="pt-16 pb-16 bg-slate-50">
        <div className="flex justify-center space-x-6">
          {socialLinks?.length > 1 &&
            socialLinks.map((social) => {
              const { name, url, logo } = social;

              return (
                <a href={url} key={name} className="text-gray-400 hover:text-black" target="_blank" rel="noreferrer">
                  <span className="sr-only">{name}</span>
                  {logo}
                </a>
              );
            })}
        </div>
        <p className="mt-8 text-base leading-6 text-center text-gray-400">
          © {new Date().getFullYear()}&nbsp;mamanofficial.com - All rights reserved.
        </p>
      </footer>
    </BrowserRouter>
  );
}

export default App;
